import { HeadFC, Script, graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { Product, ProductCategory } from "../types";
import List from "../components/Products/List";
import Breadcrumb from "../components/common/Breadcrumb";
import Layout from "../components/layout/Layout";
import Seo from "../components/Seo";
import { getHead } from "../services/rankMathServices";
import parse from "html-react-parser";
import Clickmagic from "../components/common/ClickMagic/Clickmagic";

const productList = ({ data, pageContext }: any) => {
	const categoryDetails: ProductCategory = data?.categoryDetails;
	const products: Product[] = data?.products?.nodes;
	const additionalInformation =
		data?.additionalInformation?.categoryAdditinalFields;

	// console.log(additionalInformation, "<<-- additional information")

	return (
		<Layout>
			{/* {info && <Seo info={info} />} */}
			<List
				pageContext={pageContext}
				categoryName={categoryDetails?.name}
				products={products}
				additionalInformation={additionalInformation}
			/>
		</Layout>
	);
};
export default productList;

export const Head: HeadFC = ({ data }: any) => {

	const parseHtml: any = parse(data.categoryDetails.yoast_head);
	const schema: any = data.additionalInformation.categorySchemaInformation.categorySchema;

	const index =
		parseHtml &&
		parseHtml?.length > 0 &&
		parseHtml.findIndex(
			(item: any) => item?.props?.type === "application/ld+json"
		);

	if (index > -1) {
		parseHtml.splice(index, 1);
	}

	return (
		<>
			{parseHtml}
			<Seo Schema={schema} info={data.categoryDetails.yoast_head} />

		</>
	);
};
export const query = graphql`
	query ($id: Int) {
		categoryDetails: wcProductsCategories(wordpress_id: { eq: $id }) {
			id
			name
			slug
			yoast_head
		}

		additionalInformation: wpProductCategory(databaseId: { eq: $id }) {
			id
			categorySchemaInformation {
      			categorySchema
    		}
			categoryAdditinalFields {
				banner_text_1
				banner_text_2
				banner_text_3
				banner_bg_1 {
					node {
						id
						publicUrl
						altText
					}
				}
				banner_bg_2 {
					node {
						id
						publicUrl
						altText
					}
				}
				banner_mobile_bg_1 {
					node {
						id
						publicUrl
						altText
					}
				}
				banner_mobile_bg_2 {
					node {
						id
						publicUrl
						altText
					}
				}
				banner_image {
					node {
						id
						publicUrl
						altText
					}
				}
				thumbnail_1 {
					node {
						id
						publicUrl
						altText
					}
				}
				thumbnail_2 {
					node {
						id
						publicUrl
						altText
					}
				}
				category_rich_description
			}
		}

		products: allWcProducts(
			sort: { date_created: ASC }
			filter: { categories: { elemMatch: { wordpress_id: { eq: $id } } } }
		) {
			nodes {
				id: wordpress_id
				name
				slug
				regular_price
				manage_stock
				stock_quantity
				price
				images {
					id
					name
					src
					alt
				}
				stock_status
				type
				product_variations {
					id
					name
					regular_price
					stock_quantity
					price
					image {
						id
						name
						src
						alt
					}
					stock_status
					type
				}
				grouped_products_nodes {
					id
					name
					slug
					regular_price
					stock_quantity
					price
					images {
						id
						name
						src
						alt
					}
					stock_status
					type
					product_variations {
						id
						name
						regular_price
						stock_quantity
						price
						image {
							id
							name
							src
							alt
						}
						stock_status
						type
					}
				}
			}
		}
	}
`;
